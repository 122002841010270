import request from "@/api/config.js";

export function updateSolution(id, data) {
  return request({
    url: `solution/${id}`,
    method: "put",
    data,
  });
}

export function getAllSolutionNotChecked() {
  return request({
    url: `solution/get/not-checked`,
    method: "get",
  });
}

export function getStatuses() {
  return request({
    url: `solution/get/statuses`,
    method: "get",
  });
}

export function createSolution(data) {
  return request({
    url: `solution/create`,
    method: "post",
    data,
  });
}

export function setTestStatus(data) {
  return request({
    url: `solution/set/test/status`,
    method: "post",
    data,
  });
}

export function findByUserAndLevel(data) {
  return request({
    url: `solution/get/by-user-and-level`,
    method: "post",
    data,
  });
}

export function getAllSolutions() {
  return request({
    url: `solution/get/all`,
    method: "get",
  });
}

export function findAndDelete(user_id, level_id, task_id, solution_id) {
  return request({
    url: `solution/remove-solution/${user_id}/${level_id}/${task_id}/${solution_id}`,
    method: "get",
  });
}
